<template>
  <div class="col-lg-4 col-sm-12">
    <div class="card">
      <div class="card-header">
        Raport czasowy
      </div>
      <div class="card-body">
        <form class="form">
          <div class="row">
            <div class="col">
              <input class="form-control" type="date" v-model="from" />
            </div>
            <div class="col mt-2">
              <input class="form-control" type="date" v-model="to" />
            </div>
          </div>
          <div class="buttons">
            <div class="d-flex justify-content-center" v-if="isRequest">
              <div class="spinner-border spinner-border-sm mt-3" />
            </div>
            <a class="btn btn-primary mt-3 mb-3" type="button" v-if="!isRequest" v-on:click="getFileURL()" target="_blank">Wygeneruj raport</a>
            <a class="btn btn-success mt-3 mb-3" type="button" :href="this.signedUrl" v-if="this.signedUrl !== '' && this.signedUrl !== false" target="_blank">Pobierz raport</a>
          </div>
        </form>
        <div v-if="message !== ''" class="card-footer" :class="messageIsError ? 'error-card-form' : 'success-card-form'" v-html="message"></div>
      </div>
    </div>
  </div>
</template>

<script>

import api from "@/api";

export default {
  name: "TimeReports",
  components: {},
  data(){
    return {
      isRequest: false,
      from: this.getDefaultFromDate(),
      to: this.getDefaultToDate(),
      signedUrl: '',
      message: '',
      messageIsError: false,
    }
  },
  methods: {
    getDefaultFromDate(){
      let date = new Date();
      date.setDate(1);
      return date.toISOString().substring(0, 10);
    },
    getDefaultToDate(){
      let date = new Date();
      return date.toISOString().substring(0, 10);
    },
    getFileURL(){
      this.isRequest = true;
      api.getTimeReports(this.from, this.to).then((res) => {
        if (res.data.message === 'ok') {
          this.messageIsError = false;
          this.message = '';
          this.signedUrl = res.data.url;
        } else {
          this.message = 'Wystąpił problem podczas przetwarzania zapytania.';
          this.messageIsError = true;
        }
      }).catch((err) => {
        console.error(err);
        this.message = 'Wystąpił błąd podczas wysyłania zapytania.';
        this.messageIsError = true;
      }).finally(() => {
        this.isRequest = false;
      })
    }
  }
}
</script>

<style>
.error-card-form{
  background-color: red;
}

.success-card-form{
  background-color: green;
}

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
